import axios from "axios";

class expensesTypeApi {
  async get() {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/expenses/service/get`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async add(name, note) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post(`/expenses/service/add`, {
        name,
        note,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async edit( name, note, id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .put(`/expenses/service/edit`, {
        name,
        note,
        id,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .delete(`/expenses/service/delete/${id}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }
}

export default new expensesTypeApi();
